import { FC, memo, useState } from 'react'
import classNames from 'classnames'
import { Anchor, Button, InputBase, NumberInput, Stack, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconCurrencyRubel, IconPhone, IconUser, IconCreditCard } from '@tabler/icons-react'
import { IMaskInput } from 'react-imask'
import { useTranslation } from 'react-i18next'
import { useStyles } from './WithdrawalMoneyForm.styles'
import { WithdrawalMoneyRequest } from '../../model/types/withdrawalMoney'
import { useWithdrawalMoney } from '../../api/withdrawalMoneyApi'
import { AlertYt } from '@/shared/ui/AlertYt/AlertYt'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { globalAlertModalActions } from '@/widgets/GlobalAlertModal'

interface WithdrawalMoneyFormProps {
	className?: string
	currentBalancePrint: string
	currentBalance: number
	onCloseModal?: () => void
}

export const WithdrawalMoneyForm: FC<WithdrawalMoneyFormProps> = memo((props) => {
	const { className, currentBalancePrint, currentBalance, onCloseModal } = props
	const { classes } = useStyles()
	const { t } = useTranslation('balance')

	const [error, setError] = useState<string>('')
	const [withdrawalMoney, { isLoading }] = useWithdrawalMoney()

	const dispatch = useAppDispatch()

	const handlerWithdrawal = async (values: WithdrawalMoneyRequest) => {
		try {
			await withdrawalMoney(values).unwrap()
			dispatch(
				globalAlertModalActions.reducerAlertDetails({
					type: 'success',
					title: t('Успешное оформление'),
					message: t('Заявка на вывод средств отправлена на модерацию'),
				}),
			)
			onCloseModal && onCloseModal()
		} catch (e: any) {
			console.log(e.data.message)
			setError(e.data.message)
		}
	}

	const form = useForm<WithdrawalMoneyRequest>({
		initialValues: {
			fio: '',
			sum: '',
			card: '',
			phone: '',
		},

		validate: (values) => {
			const errors: Partial<Record<keyof WithdrawalMoneyRequest, string>> = {}

			if (!values.fio.trim()) {
				errors.fio = t('Это обязательное поле!')
			}

			if (!values.phone.trim()) {
				errors.phone = t('Это обязательное поле!')
			}

			if (!values.card.trim()) {
				errors.card = t('Это обязательное поле!')
			}

			if (!values.sum) {
				errors.sum = t('Введите желаемую сумму')
			}

			if (values.sum < 1000) {
				errors.sum = t('Сумма вывода должна быть не менее 1000 рублей')
			}

			if (values.sum > currentBalance) {
				errors.sum =
					t('Сумма вывода не должна превышать остаток на балансе') + ` ${currentBalance} ` + t('рублей')
			}

			if (values.sum > 120000) {
				errors.sum = t('Сумма вывода не должна быть более 120000 рублей')
			}

			return errors
		},
	})

	return (
		<form
			className={classNames(classes.withdrawalMoneyForm, {}, className)}
			onSubmit={form.onSubmit((values) => handlerWithdrawal(values))}
		>
			<Stack>
				<Text>
					{t('Доступно к выводу')}{' '}
					<Text fw={600} span>
						{currentBalancePrint} ₽
					</Text>
				</Text>
				<Text>
					Укажите реквизиты для формирования запроса на вывод.
					<br />В течение 14 рабочих дней денежные средства будут отправлены на вашу карту.
					<br /> Комиссия банка свыше 2000 руб. составит 3% минимум 40 рублей.
				</Text>
				<Text>Выплаты производятся на карты РФ и карты платежной системы МИР.</Text>
				<Text>
					Для получения выплаты альтернативным способом свяжитесь с технической командой{' '}
					<Anchor href={'mailto:support@yourtunes.ru'} target={'_blank'}></Anchor>
				</Text>
				<TextInput
					label={t('ФИО')}
					placeholder={t('Введите ФИО полностью')}
					{...form.getInputProps('fio')}
					icon={<IconUser />}
				/>
				{/*<InputBase
					component={IMaskInput}
					mask="+7 (000) 000 00 00"
					label={t('Телефон')}
					placeholder={t('Введите Ваш номер телефона')}
					{...form.getInputProps('phone')}
					icon={<IconPhone />}
				/>*/}
				<TextInput
					label={t('Телефон')}
					placeholder={t('Введите Ваш номер телефона')}
					{...form.getInputProps('phone')}
					icon={<IconPhone />}
				/>
				{/*<InputBase
					component={IMaskInput}
					mask="0000 0000 0000 0000 00"
					label={t('Номер карты')}
					placeholder={t('Введите Ваш номер карты')}
					{...form.getInputProps('card')}
					icon={<IconCreditCard />}
				/>*/}
				<TextInput
					label={t('Номер карты')}
					placeholder={t('Введите Ваш номер карты')}
					{...form.getInputProps('card')}
					icon={<IconCreditCard />}
				/>
				<NumberInput
					label={t('Сумма вывода')}
					placeholder={t('Введите сумму')}
					{...form.getInputProps('sum')}
					icon={<IconCurrencyRubel />}
				/>
				<Text>{t('От 1000 до 120000 ₽')}</Text>
				{error && <AlertYt onClose={setError}>{error}</AlertYt>}
				<Button color={'dark'} type={'submit'} loading={isLoading}>
					{t('Вывести')}
				</Button>
			</Stack>
		</form>
	)
})

WithdrawalMoneyForm.displayName = 'WithdrawalMoneyForm'
