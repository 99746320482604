import { FC, memo } from 'react'
import { Button, Modal, Skeleton, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import { useStyles } from './WithdrawalMoneyButton.styles'
import { WithdrawalMoneyForm } from '../WithdrawalMoneyForm/WithdrawalMoneyForm'
import { Balance } from '@/entities/Balance'

interface WithdrawalMoneyButtonProps {
	isLoading: boolean
	balanceData?: Balance
}

export const WithdrawalMoneyButton: FC<WithdrawalMoneyButtonProps> = memo((props) => {
	const { isLoading, balanceData } = props

	const { t } = useTranslation('balance')

	const { classes } = useStyles()

	const [opened, { open, close }] = useDisclosure()

	if (isLoading) return <Skeleton w={'100%'} h={35} />

	if (!balanceData) return null

	const { balance_print, balance } = balanceData

	return (
		<>
			<Button
				className={classes.withdrawalMoneyButton}
				fullWidth
				color={'dark'}
				onClick={open}
				disabled={balance < 1000}
			>
				{t('Вывести')}
			</Button>
			<Modal
				opened={opened}
				onClose={close}
				centered
				size={'lg'}
				title={
					<Text fw={600} fz={'lg'}>
						{t('Вывод средств')}
					</Text>
				}
			>
				<WithdrawalMoneyForm
					currentBalance={balance}
					currentBalancePrint={balance_print}
					onCloseModal={close}
				/>
			</Modal>
		</>
	)
})

WithdrawalMoneyButton.displayName = 'WithdrawalMoneyButton'
